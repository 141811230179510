export const knownTokens = {};

const known_chains = [
    {
        name: 'Arbitrum One',
        id: 42161,
        image: '/arbitrum-logo.svg',
    }
]

const arbitrumTokens = [
    {
        name: 'Wrapped Ether',
        symbol: 'WETH',
        decimals: 18,
        image: null,
        address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    },
    {
        name: 'USD Coin (Bridged)',
        symbol: 'USDC.e',
        decimals: 6,
        image: null,
        address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    },
    {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 6,
        image: null,
        address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    },
    {
        name: 'Wrapped Bitcoin',
        symbol: 'WBTC',
        decimals: 8,
        image: null,
        address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    },
    {
        name: 'DAI Stablecoin',
        symbol: 'DAI',
        decimals: 18,
        image: null,
        address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    {
        name: 'Uniswap',
        symbol: 'UNI',
        decimals: 18,
        image: null,
        address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
    },
    {
        name: 'Chainlink Token',
        symbol: 'LINK',
        decimals: 18,
        image: null,
        address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
    },
    {
        name: 'USD Coin (Native)',
        symbol: 'USDC',
        decimals: 6,
        image: null,
        address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    },
    {
        name: 'TrueUSD',
        symbol: 'TUSD',
        decimals: 18,
        image: null,
        address: '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07',
    },
    {
        name: 'Lido DAO Token',
        symbol: 'LDO',
        decimals: 18,
        image: null,
        address: '0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
    },
    {
        name: 'Arbitrum',
        symbol: 'ARB',
        decimals: 18,
        image: null,
        address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    },
];

const _knownTokens = {
    1337: [], // only show the two mock coins
    31337: arbitrumTokens,
    42161: arbitrumTokens,
};

for( const chainId in _knownTokens ) {
    knownTokens[chainId] = {}
    for( const info of _knownTokens[chainId] )
        knownTokens[chainId][info.address] = info
}

