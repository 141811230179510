/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const darkGray = '#333'

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          background: '#ffffff',
          surface: '#ffffff',
          primary: '#35D721',
          success: '#35D721',
          warning: '#FFDE00',
          error: '#DD1A10',
          "on-background": darkGray,
          "on-surface": darkGray,
          "on-primary": darkGray,
          "on-secondary": darkGray,
          "on-success": darkGray,
          "on-info": darkGray,
          "on-warning": darkGray,
          "on-error": darkGray,
        },
        dark: false,
        variables: {},
      },
      dark: {
        colors: {
          background: '#000',
          surface: '#000',
          primary: '#35D721',
          success: '#35D721',
          warning: '#FFDE00',
          error: '#DD1A10',
          "on-background": darkGray,
          "on-surface": darkGray,
          "on-primary": darkGray,
          "on-secondary": darkGray,
          "on-success": darkGray,
          "on-info": darkGray,
          "on-warning": darkGray,
          "on-error": darkGray,
        },
        dark: true,
        variables: {},
      },
    },
  },
})
