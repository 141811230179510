/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

// const displayFonts = ['Orbitron', 'Tektur', 'Turret Road', 'Chakra Petch', 'Quantico', 'Geo']
const displayFonts = ['Orbitron']
// const bodyFonts = ['Exo 2', 'Victor Mono', 'Nunito', 'Manrope', 'Sofia Sans', 'Dosis', 'PT Sans', 'Space Grotesk', 'Rajdhani', 'Saira Semi Condensed', 'Jura']
const bodyFonts = ['Saira Semi Condensed']

export async function loadFonts () {
  return // fonts are loaded in the <head> section of index.html
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({
    google: {
      families: Array.of(...displayFonts,...bodyFonts),
    },
  })
}
