import {buildMetadataMap} from "@/common.js";

function _json(name) {
    return async function(response) {
        try {
            return await response.json()
        }
        catch (e) {
            console.error(`could not read ${name}`)
            return null
        }
    }
}

const versionPromise = fetch('/contract/version.json').then(_json('version.json'))
const metadataPromise = fetch('/metadata.json').then(_json('metadata.json'))

export const version = await versionPromise
console.log('version', version)
export const metadata = await metadataPromise
console.log('metadata', metadata)

export const metadataMap = buildMetadataMap(metadata)
