import {socket} from "@/socket.js";

const ohlcSubCounts = {} // key is route and value is a subscription counter
export const WIDE_PRICE_FORMAT = {decimals:38, width:512, signed:false}; // 38 decimals is 127 bits


export function subOHLC( chainId, pool, period ) {
    const key = `${pool}|${period}`
    const ckey = `${chainId}|${key}`
    // console.log('subOHLC', chainId, pool, period, ckey, ohlcSubCounts[ckey])
    if (!(ckey in ohlcSubCounts) || ohlcSubCounts[ckey] === 0) {
        ohlcSubCounts[ckey] = 1
        console.log('subscribing OHLCs', chainId, key)
        socket.emit('subOHLCs', chainId, [key])
    } else
        ohlcSubCounts[ckey]++
}


export function unsubOHLC( chainId, pool, period ) {
    const key = `${pool}|${period}`
    const ckey = `${chainId}|${key}`
    // console.log('unsubOHLC', chainId, pool, period, ckey, ohlcSubCounts[ckey])
    if (!(ckey in ohlcSubCounts) || ohlcSubCounts[ckey] === 0) {
        console.error('overdecremented ohlc', pool, period)
        ohlcSubCounts[ckey] = 1
    } else {
        ohlcSubCounts[ckey]--
        if (ohlcSubCounts[ckey] === 0) {
            console.log('unsubscribing OHLCs', chainId, key)
            // noinspection JSCheckFunctionSignatures
            socket.emit('unsubOHLCs', chainId, [key])
        }
    }
}


export function unsubAllOHLCs( chainId ) {
    const chainStr = chainId.toString() + '|'
    const toUnsub = []
    for( const k of Object.keys(ohlcSubCounts) ) {
        if (k.startsWith(chainStr))
            toUnsub.push(k.slice(chainStr.length))
    }
    if( toUnsub.length )
        socket.emit('unsubOHLCs', chainId, toUnsub )
}


export function subOHLCs( chainId, poolPeriods ) {
    if( !poolPeriods.length )
        return
    const toSub = []
    for( const [pool,period] of poolPeriods ) {
        const key = `${pool}|${period}`
        if (!(key in ohlcSubCounts) || ohlcSubCounts[key] === 0) {
            ohlcSubCounts[key] = 1
            toSub.push(key)
        } else
            ohlcSubCounts[key]++
    }
    if( toSub.length )
        socket.emit('subOHLCs', chainId, toSub)
}

export function unsubOHLCs( chainId, poolPeriods ) {
    const toUnsub = []
    for( const [pool,period] of poolPeriods ) {
        const key = `${pool}|${period}`
        if (!(key in ohlcSubCounts) || ohlcSubCounts[key] === 0) {
            console.error('overdecremented',pool,period)
            ohlcSubCounts[key] = 1
        } else {
            ohlcSubCounts[key]--
            if( ohlcSubCounts[key] === 0 )
                toUnsub.push(key)
        }
    }
    if( toUnsub.length )
        socket.emit('unsubOHLCs', chainId, toUnsub )
}
