// Composables
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    component: () => import('@/corp/CorpLayout.vue'),
    path:'/home',
    children: [
      {
        name: 'Home',
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '@/corp/Home.vue'),
      },
      {
        name: 'HowItWorks',
        path: '/home/how-it-works',
        component: () => import(/* webpackChunkName: "howitworks" */ '@/corp/HowItWorks.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/chart/ChartLayout.vue'),
    children: [
      {
        name: 'App',
        path: '/',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "chartorder" */ '@/components/chart/ChartPlaceOrder.vue'),
        component: () => import(/* webpackChunkName: "vaultview" */ '@/components/chart/ChartVault.vue'),
      },
      {
        name: 'Order',
        path: '/order',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chartorder" */ '@/components/chart/ChartPlaceOrder.vue'),
      },
      {
        name: 'Assets',
        path: '/assets',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "vaultview" */ '@/components/chart/ChartVault.vue'),
      },
      {
        name: 'Status',
        path: '/status',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "ordersview" */ '@/components/chart/ChartStatus.vue'),
      },
/*
      {
        name: 'Create',
        path: '/order',
        component: () => import(/!* webpackChunkName: "chartorder" *!/ '@/components/chart/ChartPlaceOrder.vue'),
      },
      {
        name: 'TWAP',
        path: '/twap',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "twap" *!/ '@/components/TimedOrder.vue'),
      },
      {
        name: 'Ladder',
        path: '/ladder',
        component: () => import(/!* webpackChunkName: "ladder" *!/ '@/components/LadderOrder.vue'),
      },
      {
        name: 'Diagonal',
        path: '/diagonal',
        component: () => import(/!* webpackChunkName: "diagonal" *!/ '@/components/DiagonalOrder.vue'),
      },
      {
        name: 'Custom',
        path: '/custom',
        component: () => import(/!* webpackChunkName: "custom" *!/ '@/components/CustomOrder.vue'),
      },
*/
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
