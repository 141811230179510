/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'
import '@/styles/style.scss'
import {detectChain} from "@/blockchain/wallet.js";
import "./socket.js"
import "./version.js"

BigInt.prototype.toJSON = function() { return this.toString() }

const app = createApp(App)
registerPlugins(app)
app.mount('#app')
detectChain()
window.$vuetify = app
